import React from 'react'
import loadable from '@loadable/component'

const HubspotAssessment = loadable(() => import('../common/hubspot-assessment'))
const LiteYoutubeEmbedHere = loadable(() => import('../common/lite-youtube-embed-here'))

const PageVOIPDesignSection = () => (
  <>
    <div className="xl:hidden w-full xl:w-1/2 mx-auto">
      <HubspotAssessment />
    </div>

    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>VOIP Network Phone</h2>
          <p className='text-lg text-gray-800 mb-4'>Is VOIP the right solution for you? We can take a look at your current phone system and bill and compare not only the cost benefit but also features including:</p>
          <ul className='list-disc mb-6 ml-8'>
            <li className='text-lg text-gray-800 mb-4'>Softphone (cell phone) integration</li>
            <li className='text-lg text-gray-800 mb-4'>Auto attendant setup</li>
            <li className='text-lg text-gray-800 mb-4'>Call routing</li>
            <li className='text-lg text-gray-800 mb-4'>Call Center integration</li>
            <li className='text-lg text-gray-800 mb-4'>Outlook and Web integration</li>
          </ul>
          <p className='text-lg text-gray-800'>Our team can design, implement and install VOIP phone systems that integrate with your current wiring and network infrastructure.</p>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <LiteYoutubeEmbedHere youtubeID="IET7sUR0vlc" />
        </div>
      </div>
    </div>
  </>
)

export default PageVOIPDesignSection